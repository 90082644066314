import { Component, ElementRef, OnInit, Renderer, ViewEncapsulation, ViewChildren, QueryList} from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { APIResponse } from 'src/app/shared/models/api-response';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-toddler9',
  templateUrl: './toddler9.component.html',
  styleUrls: ['./toddler9.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class Toddler9Component implements OnInit {
  @ViewChildren('canvas') canvasElements: QueryList<ElementRef<HTMLCanvasElement>>;
  canvases = [
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
  ];

  resultData: any[] = [];
  student: Student;
  isIOS = false;


  defaultData = [
    [], // 1
    ['6136', '2355', '14253', '3917', '11559'], // 2
    ['279.37', '254.81', '156.04', '458.29', '532.07'], // 3
    [], // 4
    ['4214', '4050', '225', '2484', '5415'], // 5
    ['52', '21', '8', '63', '3'], // 6
    [], // 7
    ['202', '89', '134', '111', '157'], // 8
    ['2', '1', '18', '23', '64', '83', '27', '65', '80', '97'], // 9
    ['209.22', '147.45', '160.32', '216.72', '373.09'], // 10
    ['1584', '2211', '1675', '8827', '875'], // 11
    ['812', '258', '372', '521', '597'], // 12
    ['42', '55', '65', '39', '36'], // 13
    [], // 14
    ['7027', '16528', '3236', '1005', '19437'], // 15
    ['422.96', '516.2', '862.58', '123.19', '309.66'], // 16
    [], // 17
    ['41085', '56892', '35224', '13104', '10914'], // 18
    ['16', '26', '3', '12', '36'], // 19
    [], // 20
    ['103', '195', '153', '101', '115'], // 21
    ['11840', '8051', '10350', '5621', '2998', '1581', '1165', '736', '776', '459'], // 22
    ['119.01', '844.74', '782.95', '848.35', '415.58'], // 23
    ['720', '768', '3069', '1419', '2058'], // 24
    ['365', '842', '961', '3630', '295'], // 25
    ['35', '39', '46', '22', '30'], // 26
    ['4435', '4065', '2078', '1052', '1143', '810', '586', '469', '381', '205', '261', '208', '466'], // 27
    ['1618', '7209', '13699', '14273', '3421'], // 28
    ['499.56', '354.12', '113.95', '449.78', '457.49'], // 29
    [], // 30
    ['62805', '31640', '23364', '2976', '2268'], // 31
    ['8', '8', '5', '4', '6'], // 32
    [], // 33
    ['42', '95', '239', '714', '287', '648', '49', '99', '31', '175'], // 34
    ['1', '4', '9', '32', '5', '24', '5', '18', '16', '51'], // 35
    ['527.69', '477.8', '364.58', '124.36', '521.57'], // 36
    ['580', '686', '768', '3915', '2352'], // 37
    ['869', '395', '621', '1141', '487'], // 38
    ['35', '52', '33', '41', '41'], // 39
    [], // 40
    ['19482', '8137', '12158', '3933', '6537'], // 41
    ['79.9', '277.01', '123.32', '390.76', '619.24'], // 42
    [], // 43
    ['526932', '245754', '511032', '226872', '131184'], // 44
    ['31', '34', '73', '96', '68'], // 45
    [], // 46
    ['11', '41', '8', '35', '13', '29', '10', '67', '12', '53'], // 47
    ['144', '1296', '2025', '2704', '441'], // 48
    ['631.8', '478.36', '542.17', '168.29', '659.67'], // 49
    ['420', '517', '1296', '180', '336'], // 50
    ['659', '374', '557', '4762', '487'], // 51
    [], // 52
    ['49', '39', '51', '25', '29'], // 53
    [], // 54
    ['3540', '393', '12612', '10749', '4130'], // 55
    ['529.79', '894.47', '1124.7', '822.1', '327.98'], // 56
    [], // 57
    ['234171', '180486', '391494', '53997', '138861'], // 58
    ['124', '222', '222', '123', '759'], // 59
    [], // 60
    ['1', '2', '21', '110', '5', '17', '9', '22', '9', '49'], // 61
    ['729', '3136', '1089', '1849', '3721'], // 62
    ['361.76', '118.73', '362.78', '298.65', '281.52'], // 63
    ['10064', '8241', '7075', '23580', '16589'], // 64
    ['99', '76', '87', '77', '77'], // 65
    ['38', '58', '35', '46', '28'], // 66
    ['42', '19', '27', '24', '11', '13', '12', '15',
      '47', '16', '14', '17', '39', '18', '29', '38',
      '23', '37', '25', '34', '22', '28', '31', '33'], // 67
    ['16872', '30179', '13188', '10928', '20938'], // 68
    ['539.75', '877.42', '903.21', '252.68', '547.12'], // 69
    [], // 70
    ['51039', '228784', '200175', '202940', '205320'], // 71
    ['1258', '499', '924', '523', '362'], // 72
    [], // 73
    ['35', '8', '143', '12', '135', '7', '24', '1', '45', '1'], // 74
    ['256', '841', '484', '3969', '3025'], // 75
    ['668.39', '280.28', '656.41', '660.81', '387.98'], // 76
    ['37845', '14430', '5875', '11637', '26265'], // 77
    ['72', '55', '74', '76', '52'], // 78
    ['29', '47', '59', '23', '45'], // 79
    [], // 80
    ['10977', '38608', '17697', '23726', '25475'], // 81
    ['318.38', '279.62', '537.93', '310.44', '816.86'], // 82
    [], // 83
    ['628586', '499260', '274776', '580643', '323676'], // 84
    ['652', '1444', '357', '1997', '536'], // 85
    [], // 86
    ['30', '91', '253', '384', '5', '8', '75', '16', '2', '3'], // 87
    ['1521', '625', '6561', '5184', '1225'], // 88
    ['394.03', '317.54', '300.84', '143.30', '169.03'], // 89
    ['17380', '15552', '18441', '46410', '8229'], // 90
    ['73', '28', '88', '25', '57'], // 91
    ['64', '64', '69', '84', '70'], // 92
    [
      '15', '8', '12', '6', '11', '7', '12', '8', '15', '9',
      '12', '6', '13', '9', '11', '14', '9', '17', '8', '11',
      '9', '13', '7', '14', '9', '11', '6', '15', '7', '11',
      '8', '17', '9', '12', '6', '11', '8', '13', '7', '14',
      '5', '11', '4', '12', '6', '15', '6', '12', '9', '15',
      '8', '15', '6', '13', '9', '13', '6', '15', '7', '11',
      '9', '11', '8', '13', '7', '12', '8', '15', '9', '18'
    ], // 93
    ['25918', '76569', '16286', '12595', '38205'], // 94
    ['831.82', '282.3', '772.56', '385.29', '205.7'], // 95
    [], // 96
    ['4422831', '3559614', '3241728', '1497447', '345384'], // 97
    ['232', '39', '95', '72', '29'], // 98
    [], // 99
    ['35', '6', '27', '10', '2', '25', '35', '27', '3', '4'], // 100
    ['53824', '669124', '275625', '399424', '531442'], // 101
    ['141.63', '495.16', '380.46', '321.58', '153.39'], // 102
    ['2639', '63666', '27136', '34209', '27176'], // 103
    ['216', '74', '57', '85', '69'], // 104
    ['33', '55', '16', '31', '30'], // 105
    [
      '18', '14', '12', '2', '13', '17', '10', '8',
      '18', '12', '15', '1', '18', '15', '11', '0',
      '13', '16', '11', '6', '17', '13', '16', '1'
    ], // 106
    ['28501', '17684', '22568', '81844', '19065'], // 107
    ['303.95', '605.22', '558.17', '643.58', '597.33'], // 108
    [], // 109
    ['1539570', '3315158', '1004415', '3753243', '879648'], // 110
    ['99', '77', '98', '75', '58'], // 111
    [], // 112
    ['41', '180', '43', '84', '47', '30', '43', '28', '7', '4'], // 113
    ['308025', '185761', '644809', '16384', '505521'], // 114
    ['271.4', ' 454.4', '248.87', ' 807.1', '290.54'], // 115
    ['10400', '48603', '25112', '9072', '27888'], // 116
    ['33', '22', '25', '93', '66'], // 117
    ['44', '62', '46', '35', '30'], // 118
    [], // 119
    ['34352', '65434', '18528', '25469', '78246'], // 120
    ['263.87', '147.58', '339.12', '187.32', '408.35'], // 121
    [], // 122
    ['3207990', '395369', '1233648', '353079', '1343988'], // 123
    ['235', '99', '65', '113', '83'], // 124
    [], // 125
    ['177', '25', '16', '5', '4', '25', '558', '5', '31', '2'], // 126
    ['53824', '669124', '275625', '399424', '531441'], // 127
    ['372.45', '244.42', '234.77', '107.12', '363.16'], // 128
    ['2761', '14652', '19512', '34320', '16851'], // 129
    ['95', '38', '83', '58', '45'], // 130
    ['63', '70', '42', '66', '45'], // 131
  ];
  type = 'toddler9';

  constructor(
    private utilService: UtilService,
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private toastr: ToastrService,
    private renderer: Renderer
  ) { }

  ngOnInit() {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      this.isIOS = true;
    }

    for (var i = 0; i < 131; i++) {
      this.resultData.push({
        values: [],
        times: null,
        count: 0,
        correctAnswers: 10,
        resultIsIncorrect: [false,false,false,false,false,false,false,false,false,false],
        finished: false
      })
    }
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
      }

      this.httpService.post('/smartpdf/get', {
        type: this.type,
        user_id: this.student._id,
      }).then(
        (res: APIResponse) => {
          if (res.data.smartpdf) {
            this.resultData = res.data.smartpdf.data.result;
          }
          this.canvases.forEach((canvas, index) => {
            if (!canvas.context) {
              canvas.context = this.canvasElements.toArray()[index].nativeElement.getContext('2d');
            }
          });
          this.loadDrawing();
        }, () => {
         
        }
      );

      
    });


  }

  startDrawing(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  startDrawing1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing1(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  saveDrawing(index: number): void {
    this.toastr.info('Saving...');
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const dataURL = canvasElement.toDataURL();
    this.httpService.post('/smartcanvas/update', {
      type: this.type,
      user_id: this.student._id,
      serial: index,
      data: dataURL
    }).then(
      (res: APIResponse) => {
        this.toastr.clear();
        this.toastr.success('Successfully Saved');
      }, () => {
      }
    );
  }

  async loadDrawing() {
    for (var index in this.canvases) {
      this.httpService.post('/smartcanvas/get', {
        type: this.type,
        user_id: this.student._id,
        serial: index
      }).then(
        (res: APIResponse) => {
          if (res.data.smartcanvas) {
            const img = new Image();
            img.onload = () => {
            const canvasElement = this.canvasElements.toArray()[index].nativeElement;
              const context = canvasElement.getContext('2d');
              context.clearRect(0, 0, canvasElement.width, canvasElement.height);
              context.drawImage(img, 0, 0);
            };
            img.src = res.data.smartcanvas.data;
          }
        }, () => {
         
        }
      );

      await this.delay(2000);
    }
    
  }

  backToSavedImage(index: number) {
    this.httpService.post('/smartcanvas/get', {
      type: this.type,
      user_id: this.student._id,
      serial: index
    }).then(
      (res: APIResponse) => {
        if (res.data.smartcanvas) {
          const img = new Image();
          img.onload = () => {
          const canvasElement = this.canvasElements.toArray()[index].nativeElement;
            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);
            context.drawImage(img, 0, 0);
          };
          img.src = res.data.smartcanvas.data;
        }
      }, () => {
       
      }
    );
  }

  clearCanvas(index: number): void {
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const context = canvasElement.getContext('2d');
    context.clearRect(0, 0, canvasElement.width, canvasElement.height);
  }

  canvasPropertiesChanges(): void {
    this.canvases.forEach(canvas => {
      if (!canvas.isEraser) {
        canvas.context.strokeStyle = canvas.brushColor;
      }
      canvas.context.lineWidth = canvas.brushSize;
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  quastionConfirm(index) {
    this.resultData[index].finished = true;
    if (this.resultData[index].count < 2) {
      this.resultData[index].count++;
      let allCorrect = true;
      for (let i in this.defaultData[index]) {
        if (this.defaultData[index][i] != this.resultData[index].values[i]) {
          allCorrect = false;
        }
      }
      if (!allCorrect && this.resultData[index].count == 1) {
        this.toastr.error('Corrige el resultado en rojo y vuelve a picar. Solo tienes una oportunidad de corregir.');
      }
    }
    this.saveData();
  }
  quastionChange(index) {
    this.resultData[index].finished = false;
    for (let i in this.resultData[index].values) {
      if (this.resultData[index].values[i] == '') {
        this.resultData[index].values[i] = null;
      }
    }
  }

  quastionConfirm1(index) {
    this.resultData[index].finished = true;
    this.saveData();
  }

  clickedNumbers(i, j) {
    if (this.resultData[i].finished && this.resultData[i].count >= 2) {
      return;
    }
    this.resultData[i].finished = false;
    if (this.resultData[i].values[j] == '1') {
      this.resultData[i].values[j] = '0';
    } else {
      this.resultData[i].values[j] = '1';
    }
  }

  correctAnswerCheck(i, j) {
    if (!this.resultData[i].resultIsIncorrect[j] && this.resultData[i].finished){
      if (document.activeElement && document.activeElement !== document.body) {
        this.renderer.invokeElementMethod(
          this.elRef.nativeElement.ownerDocument.activeElement, 'blur');
      }
      this.resultData[i].resultIsIncorrect[j] = true;
      this.resultData[i].correctAnswers--;
      this.saveData();
    }
  }

  saveData() {
    this.toastr.info('Saving...');
    this.httpService.post('/smartpdf/update', {
      type: this.type,
      user_id: this.student._id,
      data: {
        result: this.resultData
      }
    }).then(
      (res: APIResponse) => {
        this.toastr.success('Saved');
      }, () => {
      }
    );
  }
}
