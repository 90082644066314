import { Component, ElementRef, OnInit, Renderer, ViewEncapsulation,ViewChildren, QueryList, } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { APIResponse } from 'src/app/shared/models/api-response';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-runner2',
  templateUrl: './runner2.component.html',
  styleUrls: ['./runner2.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class Runner2Component implements OnInit {
  @ViewChildren('canvas') canvasElements: QueryList<ElementRef<HTMLCanvasElement>>;
  canvases = [
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
  ];

  
  resultData: any[] = [];
  student: Student;
  isIOS = false;

  defaultData = [
    [], // 0
    [
      '80', '60', '95', '19', '90', '75', '85', '71', 
      '75', '30', '42', '40', '63', '85', '63', '80'
    ], // 1
    ['78', '75', '94', '54', '70' ,'70', '64', '14', '72', '69',
      '52', '54', '82', '62', '82' ,'92', '92', '61', '60', '35'], // 2
    [], // 3
    ['60', '60', '91', '51', '70' ,'70', '64', 
      '82', '52', '87', '71', '58' ,'76', '68'], // 4
    ['57', '65', '61', '51', '0' ,'74', '6', '84',
      '95', '46', '17', '44', '69' ,'45', '10', '15'], // 5
    [], // 6
    [
      '33', '49', '9', '40', '49' ,'60', '24', '55', '33', '99',
      '89', '22', '72', '9', '72' ,'94', '21', '71', '60', '0',
      '4', '11', '48', '2', '8'
    ], // 7
    [], // 8
    [], // 9
    [
      '54', '73', '24', '29', '39' ,'30', '72', '40', '0',
      '14', '53', '30', '17', '63' ,'43', '93', '62', '78'
    ], // 10
    ['22', '53', '50', '38', '20','50', '13', '13', '78', '79','2', '14', '11', '4'], // 11
    [], // 12
    ['57', '21', '12', '29', '41' ,'62', '12', '39', '50',
      '31', '31', '61', '0', '24' ,'9', '77', '44', '36'], // 13
    ['25', '13', '99', '4', '40', '14', '55', '29', '34', '22', '14', '17', '10', '2'], // 14
    [], // 15
    ['12', '65', '61', '7', '50','55', '59', '51', '75', '50','60', '60', '75'], // 16
    [], // 17
    ['5', '2', '3', '3', '5','4', '1', '5', '6', '4','4', '2', '3', '3', '4', '5'], // 18
    ['10', '13', '39', '24', '29' ,'16', '40', '14', '24',
      '60', '99', '82', '1', '9' ,'24', '24', '10', '39'], // 19
    ['45', '54', '99', '24', '30' ,'4', '43', '51', '40', '63',
      '0', '30', '55', '4'], // 20
    [], // 21
    ['33', '30', '37', '26', '52' ,'30', '23', '2', '14',
      '19', '90', '36', '9', '39' ,'21', '23', '50', '39'], // 22
    ['10', '82', '11', '39', '26', '22', '30', '60', '88', '65', '62', '65', '14', '14'], // 23
    [], // 24
    ['77', '79', '82', '17', '86', '18', '19', '25', '7', '15', '82', '89', '50'], // 25
    [], // 26
    [], // 27
    ['47', '29', '43', '17', '29', '27', '9', '10', '66', '12', '38', '19', '1', '10', '30', '6', '6', '99'], // 28
    ['4', '17', '11', '22', '9', '31', '3', '34', '65', '6', '69', '73', '63', '5'], // 29
    [], // 30
    ['60', '53', '17', '55', '27', '44', '77', '0', '27', '35', '20', '14', '8', '19', '5',
      '6', '39', '56'
    ], // 31
    ['20', '9', '30', '38', '8', '70', '77', '67', '16', '61', '30', '55', '32', '70'], // 32
    [], // 33
    ['19', '23', '19', '18', '64', '4', '33', '9', '19', '69', '6', '73', '23'], // 34
    [], // 35
    [], // 36
    ['66', '18', '9', '77', '39', '38', '11', '99', '4', '71',
      '66', '32', '20', '19', '40', '9', '8', '19'], // 37
    ['31', '62', '7', '62', '90', '62', '20',
      '23', '18', '0', '8', '1', '24', '14'], // 38
    [], // 39
    ['20', '6', '23', '55', '51', '65', '7', '8', '0', '7',
      '80', '33', '15', '85', '87', '80', '22', '9'], // 40
    ['18', '13', '28', '39', '28', '34', '24',
      '64', '59', '80', '40', '60', '13', '11'], // 41
    [], // 42
    ['13', '37', '4', '9', '3', '27', '18',
      '13', '24', '48', '30', '29', '78',], // 43
    [], // 44
    [], // 45
    ['19', '1', '9', '49', '9', '38', '29', '47', '19', '50',
      '44', '40', '80', '69', '44', '31', '80', '62'
    ], // 46
    ['6', '38', '20', '0', '24', '19', '29', '9', '39', '61',
      '39', '41', '39', '0'
    ], // 47
    [], // 48
    ['70', '64', '87', '9', '52', '8', '76', '48', '0', '37',
      '34', '50', '83', '59', '50', '16', '36', '77', '36', '85', 
      '51', '36'], // 49
    ['39', '13', '35', '55', '48', '61', '33', '45', '0', '40',
      '31', '9', '27', '9'], // 50
    [], // 51
    ['30', '41', '66', '95', '39', '78', '76', '32', '76', '55',
      '70', '30', '51', '49', '39', '40', '48', '76', '76', '77', '96', '97'], // 52
    [], // 53
    ['148', '51', '46', '192', '182', '152', '75', '48', '32', '222',
      '15', '58', '261', '141', '88'], // 54
    [
      '46', '84', '45', '124', '38', '249', '279', '48', '285', '234',
      '99', '108', '138', '24', '96', '172', '134', '170', '84', '297',
      '86', '285', '165', '39', '42', '228'
    ], // 55
    [], // 56
    [
      '63', '6', '9', '21', '74', '65', '95', '30', '40', '10',
      '8', '33', '7', '13', '70', '9', '62', '4', '8', '11',
      '23', '18'
    ], // 57
    [
      '28', '44', '20', '42', '58', '54', '51', '91', '32', '65',
      '70', '88', '70', '68'
    ], // 58
    [], // 59
    [
      '69', '50', '24', '59', '58', '49', '55', '14', '38', '23',
      '14', '51', '93', '15', '79', '3', '26', '31', '57', '23',
      '75', '12'
    ], // 60
    [
      '91', '82', '49', '23', '65', '23', '10', '48', '90', '14',
      '30', '86', '54', '14'
    ], // 61
    [], // 62
    [
      '19', '56', '56', '1', '16', '3', '58', '86', '9', '0',
      '9', '55', '65', '50', '6', '55', '2', '1', '69', '65',
      '56', '95', '69', '69'
    ], // 63
    [], // 64
    [
      '72', '124', '110', '36', '51', '204', '166', '132', '44', '285',
      '234', '198', '78', '207', '84'
    ], // 65
    [
      '64', '192', '81', '156', '170', '62', '88', '105', '30', '216',
      '102', '282', '288', '23', '42', '130', '72', '57', '94', '25',
      '207', '61', '132', '116', '108', '71'
    ], // 66
    [], // 67
    [
      '94', '9', '24', '73', '44', '80', '12', '96', '95', '44',
      '69', '94', '85', '94', '31', '68', '19', '95', '84', '64',
      '71', '55'
    ], // 68
    [
      '94', '64', '41', '99', '14', '13', '14', '9', '13', '11',
      '15', '11', '14', '14'
    ], // 69
    [], // 70
    [
      '99', '83', '34', '33', '24', '34', '72', '67', '94', '56',
      '29', '93', '24', '64', '84', '34', '84', '13', '74', '79',
      '30', '83'
    ], // 71
    [
      '24', '4', '74', '2', '50', '65', '48', '98', '44', '27',
      '72', '42', '41', '47'
    ], // 72
    [], // 73
    [
      '5', '40', '65', '78', '60', '40', '50', '33', '79', '70',
      '20', '85', '79', '43', '70', '78', '28', '86', '36', '56',
      '61', '59', '38', '34'
    ], // 74
    [], // 75
    [
      '297', '69', '102', '168', '204', '252', '385', '410', '465', '52',
      '135', '152', '215', '228', '87'
    ], // 76
    [
      '36', '69', '111', '135', '156', '201', '228', '252', '396', '48',
      '88', '140', '180', '232', '244', '316', '445', '455', '95', '105',
      '190', '215', '285', '320'
    ], // 77
    [
      '2', '3', '4', '6', '4', '6', '2', '3', '1', '5',
      '6', '3', '2', '6', '1', '4', '3', '5', '4', '1',
      '1', '2', '6', '3'
    ], // 78
    [
      '80', '43', '62', '76', '84', '84', '78', '44', '49', '3',
      '37', '84', '41', '69', '93', '34', '42', '24', '24', '62',
      '45', '24'
    ], // 79
    [
      '67', '73', '24', '40', '8', '16', '17', '17', '28', '30',
      '48', '14', '18', '14'
    ], // 80
    [], // 81
    [
      '44', '56', '43', '90', '21', '33', '76', '70', '62', '88',
      '9', '43', '91', '48', '55', '69', '53', '94', '77', '90',
      '62', '69'
    ], // 82
    [
      '66', '62', '44', '60', '48', '10', '30', '73', '45', '43',
      '73', '61', '85', '54'
    ], // 83
    [], // 84
    [
      '86', '77', '60', '89', '72', '90', '38', '51', '97', '43',
      '75', '57', '85', '66', '99', '22', '21', '45', '80', '40',
      '99', '97', '10', '31', '21'
    ], // 85
    [], // 86
    [
      '85', '125', '165', '252', '354', '360', '539', '623', '630', '55',
      '126', '273', '294', '330'
    ], // 87
    [
      '100', '56', '248', '116', '104', '352', '220', '272', '235', '385',
      '245', '435', '205', '325', '230', '120', '66', '270', '168', '156',
      '432', '564', '252', '273', '546', '308', '511', '567', '595', '413'
    ], // 88
    [], // 89
    [
      '45', '50', '9', '41', '0', '9', '19', '50', '25', '55',
      '36', '0', '45', '60', '11', '25', '25', '6', '65', '45',
      '30', '16'
    ], // 90
    [
      '85', '21', '55', '18', '22', '51', '37', '40', '52', '48',
      '81', '34', '81', '82'
    ], // 91
    [], // 92
    [
      '15', '65', '99', '35', '5', '6', '74', '46', '47', '59',
      '27', '44', '19', '42', '24', '30', '14', '50', '15', '16',
      '28', '58'
    ], // 93
    [
      '13', '38', '30', '68', '15', '5', '25', '15'
    ], // 94
    [], // 95
    [
      '56', '91', '70', '98', '91', '71', '30', '83', '60', '78',
      '69', '98', '76', '98', '20', '91', '75', '92', '91', '81',
      '22', '92', '11', '42', '60'
    ], // 96
    [], // 97
    [
      '679', '294', '455', '680', '296', '592', '693', '261', '747', '476',
      '88', '198', '378', '776', '495'
    ], // 98
    [
      '228', '570', '390', '198', '78', '558', '270', '576', '224', '476',
      '98', '434', '273', '532', '301', '232', '152', '416', '584', '176',
      '120', '792', '648', '684', '657', '792', '693', '711', '315', '171'
    ], // 99
    [
      '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '1', '1', '1', '1', '1', '0', '0', '0',
      '0', '0', '0', '1', '0', '1', '0', '1', '0', '0', '0',
      '0', '0', '0', '0', '1', '1', '1', '1', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '1', '1', '1', '1', '1', '0', '0', '0',
      '0', '0', '0', '0', '0', '1', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '1', '0', '1', '0', '1', '0', '0',
      '0', '0', '0', '1', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0',
      '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'
    ], // 100
    [
      '27', '10', '47', '70', '39', '28', '22', '54', '55', '30',
      '44', '45', '44', '32', '34', '33', '30', '41', '30', '34',
      '70', '58'
    ], // 101
    [
      '90', '86', '25', '65', '38', '50', '28', '4', '34', '60',
      '10', '19', '38', '49'
    ], // 102
    [], // 103
    [
      '22', '7', '74', '37', '34', '4', '90', '69', '5', '95',
      '18', '47', '7', '35', '21', '99', '23', '35', '26', '4',
      '8', '14'
    ], // 104
    [
      '40', '57', '32', '21', '32', '26', '18', '86', '74', '34',
      '65', '36', '15', '67'
    ], // 105
    [], // 106
    [
      '22', '37', '52', '81', '81', '51', '40', '48', '34', '82',
      '20', '47', '51', '72', '42', '61', '42', '31', '46', '50',
      '73', '36', '49', '54', '60'
    ], // 107
    [], // 108
    [], // 109
    [
      '288', '252', '216', '272', '212', '192', '185', '130', '95', '582',
      '480', '450', '427', '371', '329', '248', '224', '128', '891', '729',
      '657', '124', '108', '84'
    ], // 110
    [
      '3', '2', '6', '3', '5', '4', '2', '5', '6', '2',
      '6', '3', '5', '4', '2', '4'
    ], // 111
    [
      '14', '41', '94', '71', '84', '19', '94', '94', '65', '59',
      '37', '76', '64', '46', '9', '24', '44', '43', '21', '76',
      '62', '9'
    ], // 112
    [
      '44', '96', '80', '99', '9', '94', '24', '44', '91', '55',
      '53', '77', '62', '66'
    ], // 113
    [], // 114
    [
      '51', '50', '34', '8', '87', '41', '39', '0', '6', '44',
      '44', '29', '9', '65', '2', '4', '83', '79', '13', '84',
      '24', '29'
    ], // 115
    [
      '38', '50', '28', '4', '69', '60', '10', '19', '38', '49',
      '22', '47', '18', '62'
    ], // 116
    [], // 117
    [
      '10', '24', '24', '9', '23', '52', '26', '33', '2', '10',
      '39', '29', '24', '39', '12', '99', '50', '31', '31', '0',
      '50', '34', '38', '40', '9'
    ], // 118
    [], // 119
    [
      '186', '86', '219', '244', '265', '423', '312', '147', '75', '372',
      '258', '584', '434', '459', '43', '78', '208', '108', '672', '510',
      '432', '512', '371', '205', '144', '56', '114', '282', '415', '280'
    ], // 120
    [
      '25', '10', '62', '14', '45', '18', '58', '20', '61', '65',
      '55', '55', '67', '53', '25', '20', '62', '65', '57', '85'
    ], // 121
    [], // 122
    [
      '44', '74', '156', '192', '216', '224', '364', '410', '160', '325',
      '180', '150', '126', '672', '574', '600', '496', '464', '387', '288'
    ], // 123
    [], // 124
    [
      '19', '24', '58', '38', '19', '61', '81', '28', '46', '41',
      '62', '18', '77', '66', '88', '70', '19', '96', '26', '79'
    ], // 125
    [], // 126
    [
      '53', '3', '12', '8', '93', '27', '15', '31', '51',
      '83', '92', '29', '22', '28', '72', '11', '48', '74'
    ], // 127
    [], // 128
  ];
  type = 'runner2';

  constructor(
    private utilService: UtilService,
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private toastr: ToastrService,
    private renderer: Renderer
  ) { }

  ngOnInit() {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      this.isIOS = true;
    }

    for (var i = 0; i < 129; i++) {
      this.resultData.push({
        values: [],
        times: '',
        count: 0,
        correctAnswers: 10,
        resultIsIncorrect: [false,false,false,false,false,false,false,false,false,false],
        finished: false
      })
    }
    
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
      }

      this.httpService.post('/smartpdf/get', {
        type: this.type,
        user_id: this.student._id,
      }).then(
        (res: APIResponse) => {
          if (res.data.smartpdf) {
            this.resultData = res.data.smartpdf.data.result;
          }
          this.canvases.forEach((canvas, index) => {
            if (!canvas.context) {
              canvas.context = this.canvasElements.toArray()[index].nativeElement.getContext('2d');
            }
          });
          this.loadDrawing();
        }, () => {
         
        }
      );
      
    });
  }

  startDrawing(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  startDrawing1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing1(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  saveDrawing(index: number): void {
    this.toastr.info('Saving...');
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const dataURL = canvasElement.toDataURL();
    this.httpService.post('/smartcanvas/update', {
      type: this.type,
      user_id: this.student._id,
      serial: index,
      data: dataURL
    }).then(
      (res: APIResponse) => {
        this.toastr.clear();
        this.toastr.success('Successfully Saved');
      }, () => {
      }
    );
  }

  async loadDrawing() {
    for (var index in this.canvases) {
      this.httpService.post('/smartcanvas/get', {
        type: this.type,
        user_id: this.student._id,
        serial: index
      }).then(
        (res: APIResponse) => {
          if (res.data.smartcanvas) {
            const img = new Image();
            img.onload = () => {
            const canvasElement = this.canvasElements.toArray()[index].nativeElement;
              const context = canvasElement.getContext('2d');
              context.clearRect(0, 0, canvasElement.width, canvasElement.height);
              context.drawImage(img, 0, 0);
            };
            img.src = res.data.smartcanvas.data;
          }
        }, () => {
         
        }
      );

      await this.delay(2000);
    }
    
  }

  backToSavedImage(index: number) {
    this.httpService.post('/smartcanvas/get', {
      type: this.type,
      user_id: this.student._id,
      serial: index
    }).then(
      (res: APIResponse) => {
        if (res.data.smartcanvas) {
          const img = new Image();
          img.onload = () => {
          const canvasElement = this.canvasElements.toArray()[index].nativeElement;
            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);
            context.drawImage(img, 0, 0);
          };
          img.src = res.data.smartcanvas.data;
        }
      }, () => {
       
      }
    );
  }

  clearCanvas(index: number): void {
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const context = canvasElement.getContext('2d');
    context.clearRect(0, 0, canvasElement.width, canvasElement.height);
  }

  canvasPropertiesChanges(): void {
    this.canvases.forEach(canvas => {
      if (!canvas.isEraser) {
        canvas.context.strokeStyle = canvas.brushColor;
      }
      canvas.context.lineWidth = canvas.brushSize;
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  quastionConfirm(index) {
    this.resultData[index].finished = true;
    if (this.resultData[index].count < 2) {
      this.resultData[index].count++;
      let allCorrect = true;
      for (let i in this.defaultData[index]) {
        if (this.defaultData[index][i] != this.resultData[index].values[i]) {
          allCorrect = false;
        }
      }
      if (!allCorrect && this.resultData[index].count == 1) {
        this.toastr.error('Corrige el resultado en rojo y vuelve a picar. Solo tienes una oportunidad de corregir.');
      }
    }
    this.saveData();
  }

  quastionChange(index) {
    this.resultData[index].finished = false;
  }

  clickedNumbers(i, j) {
    if (this.resultData[i].finished && this.resultData[i].count >= 2) {
      return;
    }
    this.resultData[i].finished = false;
    if (this.resultData[i].values[j] == '1') {
      this.resultData[i].values[j] = '0';
    } else {
      this.resultData[i].values[j] = '1';
    }
  }

  quastionConfirm1(index) {
    this.resultData[index].finished = true;
    this.saveData();
  }

  correctAnswerCheck(i, j) {
    if (!this.resultData[i].resultIsIncorrect[j] && this.resultData[i].finished){
      if (document.activeElement && document.activeElement !== document.body) {
        this.renderer.invokeElementMethod(
          this.elRef.nativeElement.ownerDocument.activeElement, 'blur');
      }
      this.resultData[i].resultIsIncorrect[j] = true;
      this.resultData[i].correctAnswers--;
      this.saveData();
    }
  }

  saveData() {
    this.toastr.info('Saving...');
    this.httpService.post('/smartpdf/update', {
      type: this.type,
      user_id: this.student._id,
      data: {
        result: this.resultData
      }
    }).then(
      (res: APIResponse) => {
        this.toastr.success('Saved');
      }, () => {
      }
    );
  }
}
