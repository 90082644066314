import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TooltipModule}  from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';

import { StudentProfileResolver } from '../shared/resolvers/get-student-profile-resolver.service';
import { StudentProgressResolver } from '../shared/resolvers/get-student-progress-resolver.service';
import { GetAllLevelsResolver } from '../shared/resolvers/get-all-levels-resolver.service';
import { GetNotificationsResolver } from '../shared/resolvers/get-notifications-resolver.service';
import { GetHomeworkQuestionsResolver } from '../shared/resolvers/get-homework-questions-resolver.service';
import { GetNextLessonResolver } from '../shared/resolvers/get-next-lesson-resolver.service';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../shared.module';
import { LevelComponent } from './level/level.component';
import { MobileNavfooterComponent } from './navs/mobile-navfooter/mobile-navfooter.component';
import { MobileNavtopComponent } from './navs/mobile-navtop/mobile-navtop.component';
import { DesktopNavComponent } from './navs/desktop-nav/desktop-nav.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { ClassroomComponent } from './classroom/classroom.component';
import { HomeworkComponent } from './homework/homework.component';
import { MatchroomComponent } from './matchroom/matchroom.component';
import { DesktopNavIconComponent } from './navs/desktop-nav-icon/desktop-nav-icon.component';
import { SetAvatarComponent } from './set-avatar/set-avatar.component';
import { LogoutComponent } from './logout.component';
import { AvatarModule } from '../shared/avatar/src/public-api';
import { NotificationComponent } from './notification/notification.component';
import { BourdonComponent } from './bourdon/bourdon.component';
import { QuestionsComponent } from './questions/questions.component';
import { AbacusComponent } from './abacus/abacus.component';
import { MatchQuestionsComponent } from './match-questions/match-questions.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { ProfilecardComponent } from './profilecard/profilecard.component';
import { HallofameComponent } from './hallofame/hallofame.component';
import { SmartpdfComponent } from './smartpdf/smartpdf.component';
import { Toddler7Component } from './toddler7/toddler7.component';
import { Runner1Component } from './runner1/runner1.component';
import { Runner2Component } from './runner2/runner2.component';
import { Toddler6Component } from './toddler6/toddler6.component';
import { Toddler8Component } from './toddler8/toddler8.component';
import { Toddler9Component } from './toddler9/toddler9.component';

FullCalendarModule.registerPlugins([interactionPlugin, dayGridPlugin, timeGridPlugin, bootstrapPlugin, listPlugin]);


@NgModule({
    declarations: [
        ProfileComponent,
        DashboardComponent,
        LevelComponent,
        MobileNavfooterComponent,
        MobileNavtopComponent,
        DesktopNavComponent,
        DashboardHomeComponent,
        ClassroomComponent,
        HomeworkComponent,
        MatchroomComponent,
        DesktopNavIconComponent,
        SetAvatarComponent,
        LogoutComponent,
        NotificationComponent,
        BourdonComponent,
        QuestionsComponent,
        AbacusComponent,
        MatchQuestionsComponent,
        ProfilecardComponent,
        HallofameComponent,
        SmartpdfComponent,
        Toddler9Component,
        Toddler8Component,
        Toddler7Component,
        Toddler6Component,
        Runner1Component,
        Runner2Component
    ],
    imports: [
        CommonModule,
        BrowserModule,
        NgApexchartsModule,
        BrowserAnimationsModule,
        SharedModule,
        MatRadioModule,
        MatSelectModule,
        MatFormFieldModule,
        MatIconModule,
        ReactiveFormsModule,
        MatDividerModule,
        MatButtonModule,
        AvatarModule,
        AngularFontAwesomeModule,
        DashboardRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FullCalendarModule,
        TooltipModule.forRoot(),
        ToastrModule.forRoot(
            {
                timeOut: 6000,
                positionClass: 'toast-bottom-right',
            }
        ), 
    ],
    exports: [
    ],
    providers: [
        StudentProfileResolver,
        StudentProgressResolver,
        GetAllLevelsResolver,
        GetNotificationsResolver,
        GetHomeworkQuestionsResolver,
        GetNextLessonResolver,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class DashboardModule {}