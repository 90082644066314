import { Component, ElementRef, OnInit, Renderer, ViewEncapsulation,ViewChildren, QueryList, } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { HandleRequestService } from 'src/app/shared/services/handle-request.service';
import { APIResponse } from 'src/app/shared/models/api-response';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/shared/models/student';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-runner1',
  templateUrl: './runner1.component.html',
  styleUrls: ['./runner1.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class Runner1Component implements OnInit {
  @ViewChildren('canvas') canvasElements: QueryList<ElementRef<HTMLCanvasElement>>;
  canvases = [
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
    { brushColor: '#000000', brushSize: 1, isEraser: false, drawing: false, context: null },
  ];
  
  resultData: any[] = [];
  student: Student;
  isIOS = false;

  defaultData = [
    ['4', '3', '2', '31', '0', '22', '23', '17', 
      '45', '1','21', '30', '1', '12'], // 0
    ['20', '16', '4', '59', '30', '32', '3', '98', 
      '81', '0','8', '18', '63', '38'], // 1
    ['70', '60', '2', '65', '50', '17', '90', '11', '28', 
      '90', '87','36', '33', '85', '0','33', '20', '9'], // 2
    ['47', '55', '0', '20', '93', '9', '53', '55', '88', 
      '67', '90','65', '5', '5', '99','98', '93', '91'], // 3
    [], // 4
    [], // 5
    ['1','2','4','3','1','3'], // 6
    ['66', '0', '78', '99', '99', '2', '85', '55', '50', 
      '89', '77','99', '44', '83', '6','90', '43', '20',
    '7', '2','53', '0', '9', '67','6', '94', '98'], // 7
    ['22', '94', '1', '4', '60', '66', '7', '29', '36', 
      '15', '71','17', '19', '19', '5','18', '95', '43',
    '28', '55','84', '20', '10', '39','1', '44', '79', '63','81', '40', '76'], // 8
    [], // 9
    [], // 10
    ['0', '0', '0', '1', '1', 
     '0', '0', '1', '0', '1',
     '1', '0', '0', '0', '0',
     '0', '0', '1', '0', '0', 
     '0', '0', '0', '0', '1'], // 11
    ['9', '0', '7', '49', '31', '77', '10', '2', '40', 
      '56', '98','59', '94', '80'], // 12
    ['75', '16', '89', '64', '74', '49', '16', '86', '0', 
      '46', '79','45', '0', '0'], // 13
    ['82', '89', '15', '47', '20', '46', '22', '0', '88', 
      '37', '89','87', '76', '75', '86','71', '36', '12'], // 14
    ['76', '35', '29', '69', '76', '25', '45', '82', '99', 
      '11', '79','35', '0', '50', '37','69', '49', '0'], // 15
    [], // 16
    [], // 17
    ['9', '9', '16', '66', '11', '55', '60', '0', '60', 
      '9', '6','40', '0', '99'], // 18
    ['60', '30', '95', '69', '9', '59', '31', '0', '50', 
      '50', '50','59', '69', '50'], // 19
    ['20', '29', '29', '46', '6', '26', '99', '49', '46', 
      '50', '20','49', '90', '0', '90','49', '59', '56'], // 20
    ['56', '65', '58', '2', '56', '85', '63', '69', '50', 
      '75', '87','79', '9', '77', '55','56', '75', '69'], // 21
    [], // 22
    [], // 23
    ['11', '54', '22', '20', '99', '56', '65', '58', '2', 
      '56', '82','89', '17', '0', '88','32', '30', '3'], // 24
    ['98', '18', '81', '19', '25', '59', '7', '15', '5', 
      '56', '25','0', '55', '50', '55','69', '58', '79'], // 25
    ['38', '53', '68', '20', '89', '56', '4', '35', '1', 
      '66', '69','56', '60', '0', '0','85', '55', '20'], // 26
    ['69', '11', '41', '49', '50', '76', '15', '87', '44', 
      '58', '66','56', '50', '78', '88','75', '6', '50'], // 27
    [
      '0', '0', '0', '0', '0', '1', '0', '0', '0', '0',
      '1', '0', '1', '1', '1', '1', '1', '0', '0', '0',
      '0', '1', '0', '0', '0', '1', '0', '0', '0', '0',
      '0', '0', '1', '0', '0', '1', '1', '1', '1', '1',
      '1', '1', '1', '1', '0', '1', '0', '0', '0', '0',
      '0', '0', '0', '1', '1', '1', '1', '1', '1', '0',
      '0', '0', '0', '0', '1', '1', '1', '1', '0', '0',
    ], // 28
    ['57', '16', '29', '25', '32', '22', '30', '12', '15', 
      '30', '25','0', '35', '25'], // 29
    ['18', '64', '73', '13', '13', '29', '83', '60', '44', 
      '43', '34','13', '0', '54'], // 30
    ['4', '23', '49', '24', '21', '10', '35', '26', '12', 
      '31', '41','26', '31', '33', '45','53', '30', '22'], // 31
    ['19', '9', '19', '9', '3', '24', '19', '39', '64', 
      '30', '89','34', '20', '49', '24','7', '59', '33'], // 32
    [], // 33
    [], // 34
    ['8', '14', '4', '9', '3', '29', '19', '31', '34', 
      '28', '0','24', '0', '49'], // 35
    ['7', '19', '43', '45', '65', '10', '7', '4', '65', 
      '29', '53','32', '67', '22'],// 36
    ['35', '54', '94', '49', '11', '94', '27', '14', '10', 
      '68', '45','49', '49', '26', '3','73', '33', '92'], // 37
    ['30', '42', '41', '7', '32', '19', '16', '43', '10', 
      '29', '45','65', '19', '67', '7','49', '67', '4'], // 38
    [], // 39
    [], // 40
    ['5', '6', '9', '11', '10', '12', '3', '3', '2', '1', '13', '16'], // 41
    ['28', '25', '16', '32', '21', '4', '58', '25', '75', 
      '64', '22','33', '26', '45', '54','93', '94', '79',
    '79', '69','34', '62', '28', '12','21', '66', '60', 
    '48','42', '11', '57'], // 42
    ['23', '44', '27', '34', '37', '33', '65', '72', '44', 
      '33', '43','5', '66', '63', '35','36', '33', '19',
    '37', '39','63', '21', '14', '59','28', '2', '35', 
    '49','15', '31', '90'], // 43
    ['7', '8', '7', '8', '6', '7', '6', '20', '23', 
      '30', '31','1', '13', '38', '12','2', '12', '22',
    '0', '30','39', '11', '24', '32'], // 44
    [], // 45
    ['2','5','4','1',
      '6','5','6','2',
      '5','6','4','1',
      '5','2','1','4',
    ], // 46
    ['55', '22', '33', '39', '15', '45', '55', '43', '0',], // 47
    ['12', '0', '79', '59', '52', '21', '10', '94', '46', '12'], // 48
    ['30', '98', '53', '62', '45', '33', '31', '41', '20', '45'], // 49
    ['50', '49', '59', '99', '79', '23', '32', '40', '15', '10', '0'], // 50
    ['44', '55', '40', '30', '59', '69', '20', '16', '16', '0', '17'], // 51
    ['85', '16', '10', '90', '61', '12', '45', '10', '94', '80', '70'], // 52
    ['4', '4', '0', '7', '6', '0', '7', '6', '49', 
      '60', '80','96', '71', '53', '51','51', '19', '95',
    '94', '29','10', '90', '29', '44', '99'], // 53
    [], // 54
    ['25'], // 55
    ['42', '80', '30', '75', '80', '80', '90', '90', '90', '20', '90'], // 56
    ['10', '51', '10', '20', '70', '40', '73', '62', '40', '89', '92'], // 57
    ['41', '41', '55', '89', '30', '25', '13', '20', '0', '41', '60'], // 58
    ['5', '79', '86', '60', '79', '59', '4', '50', '15', '62', '64'], // 59
    ['65', '44', '55', '60', '50', '65', '91', '64', '16', '26', '41'], // 60
    ['24', '16', '76', '30', '41', '66', '92', '62', '78', '40', '85'], // 61
    ['8', '8', '5', '9', '6', '7', '5', '24', '27', 
      '40', '41','42','37', '85', '38', '90','20', '9', '35',
    '60', '10','85', '21', '0'], // 62
    [], // 63
    ['8','6','2','4','9','7','5','3','1'], // 64
    ['40', '30', '17', '32', '45', '31', '30', '92', '50', '72', '61'], // 65
    ['30', '60', '10', '66', '85', '10', '11', '40', '40', '41', '80'], // 66
    ['10', '39', '30', '40', '50', '30', '90', '63', '40', '42', '40'], // 67
    ['57', '89', '0', '66', '24', '42', '40', '40', '40', '56', '41'], // 68
    ['50', '56', '51', '77', '53', '54', '49', '19', '70', '15', '60'], // 69
    ['26', '40', '18', '40', '30', '40', '40', '81', '24', '60', '61'], // 70
    ['4', '1', '9', '6', '4', '7', '2', '87', '27', 
      '30', '0','65','95', '22', '55', '37','19', '19', '25',
    '45', '19','68', '27', '61'], // 71
    [], // 72
    ['2', '7', '6',
     '9', '5', '1',
     '4', '3', '8'
    ], // 73
    ['32', '41', '30', '39', '26', '26', '75', '54', '34', 
      '54', '1','56','30', '40', '22', '98','90', '45',], // 74
    ['51', '52', '45', '4', '35', '21', '40', '34', '69', 
      '46', '40','14','50', '10', '70', '30','80', '56',], // 75
    ['56', '75', '40', '98', '22', '51', '35', '30', '22', 
      '90', '45','0','40', '4', '46', '45','69', '34',], // 76
    ['20', '4', '16', '23', '35', '15', '57', '22', '89', 
      '69', '40','47','45', '75', '82', '10','79', '98',], // 77
    ['18', '12', '94', '69', '61', '13', '84', '7', '89', 
      '70', '4','80', '23', '75', '88','76', '95', '40',
    '75', '50','91', '88', '81', '13','4', '65', '23', '5','91', '72',], // 78
    ['3', '40', '47', '27', '82', '39', '25', '40', '54', 
      '95', '69','60', '77', '15', '62','46', '45', '57',
    '15', '45','86', '45', '45', '48','55', '75', '1', '22','40', '1',], // 79
    [], // 80
    [], // 81
    [], // 82
    ['20', '87','0', '95', '27', '30','30', '65', '82', '1'], // 83
  ];
  type = 'runner1';

  constructor(
    private utilService: UtilService,
    private httpService: HandleRequestService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private toastr: ToastrService,
    private renderer: Renderer
  ) { }

  ngOnInit() {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      this.isIOS = true;
    }

    for (var i = 0; i < 130; i++) {
      this.resultData.push({
        values: [],
        times: '',
        count: 0,
        correctAnswers: 10,
        resultIsIncorrect: [false,false,false,false,false,false,false,false,false,false],
        finished: false
      })
    }
    
    this.route.data.subscribe(x => {
      if (x.hasOwnProperty('profile')) {
        const response = x.profile as APIResponse;
        this.student = response.data as Student;
      }

      this.httpService.post('/smartpdf/get', {
        type: this.type,
        user_id: this.student._id,
      }).then(
        (res: APIResponse) => {
          if (res.data.smartpdf) {
            this.resultData = res.data.smartpdf.data.result;
          }
          this.canvases.forEach((canvas, index) => {
            if (!canvas.context) {
              canvas.context = this.canvasElements.toArray()[index].nativeElement.getContext('2d');
            }
          });
          this.loadDrawing();
        }, () => {
         
        }
      );
      
    });
  }

  startDrawing(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw(e: TouchEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const event = e.touches[0];
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  startDrawing1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = true;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.beginPath();
    canvas.context.moveTo(x, y);
  }

  draw1(event: MouseEvent, index: number): void {
    const canvas = this.canvases[index];
    if (!canvas.drawing) return;
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    canvas.context.lineTo(x, y);
    canvas.context.stroke();
  }

  stopDrawing1(index: number): void {
    const canvas = this.canvases[index];
    canvas.drawing = false;
    canvas.context.closePath();
  }

  saveDrawing(index: number): void {
    this.toastr.info('Saving...');
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const dataURL = canvasElement.toDataURL();
    this.httpService.post('/smartcanvas/update', {
      type: this.type,
      user_id: this.student._id,
      serial: index,
      data: dataURL
    }).then(
      (res: APIResponse) => {
        this.toastr.clear();
        this.toastr.success('Successfully Saved');
      }, () => {
      }
    );
  }

  async loadDrawing() {
    for (var index in this.canvases) {
      this.httpService.post('/smartcanvas/get', {
        type: this.type,
        user_id: this.student._id,
        serial: index
      }).then(
        (res: APIResponse) => {
          if (res.data.smartcanvas) {
            const img = new Image();
            img.onload = () => {
            const canvasElement = this.canvasElements.toArray()[index].nativeElement;
              const context = canvasElement.getContext('2d');
              context.clearRect(0, 0, canvasElement.width, canvasElement.height);
              context.drawImage(img, 0, 0);
            };
            img.src = res.data.smartcanvas.data;
          }
        }, () => {
         
        }
      );

      await this.delay(2000);
    }
    
  }

  backToSavedImage(index: number) {
    this.httpService.post('/smartcanvas/get', {
      type: this.type,
      user_id: this.student._id,
      serial: index
    }).then(
      (res: APIResponse) => {
        if (res.data.smartcanvas) {
          const img = new Image();
          img.onload = () => {
          const canvasElement = this.canvasElements.toArray()[index].nativeElement;
            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);
            context.drawImage(img, 0, 0);
          };
          img.src = res.data.smartcanvas.data;
        }
      }, () => {
       
      }
    );
  }

  clearCanvas(index: number): void {
    const canvasElement = this.canvasElements.toArray()[index].nativeElement;
    const context = canvasElement.getContext('2d');
    context.clearRect(0, 0, canvasElement.width, canvasElement.height);
  }

  canvasPropertiesChanges(): void {
    this.canvases.forEach(canvas => {
      if (!canvas.isEraser) {
        canvas.context.strokeStyle = canvas.brushColor;
      }
      canvas.context.lineWidth = canvas.brushSize;
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  quastionConfirm(index) {
    this.resultData[index].finished = true;
    if (this.resultData[index].count < 2) {
      this.resultData[index].count++;
      let allCorrect = true;
      for (let i in this.defaultData[index]) {
        if (this.defaultData[index][i] != this.resultData[index].values[i]) {
          allCorrect = false;
        }
      }
      if (!allCorrect && this.resultData[index].count == 1) {
        this.toastr.error('Corrige el resultado en rojo y vuelve a picar. Solo tienes una oportunidad de corregir.');
      }
    }
    this.saveData();
  }

  quastionChange(index) {
    this.resultData[index].finished = false;
  }

  clickedNumbers(i, j) {
    if (this.resultData[i].finished && this.resultData[i].count >= 2) {
      return;
    }
    this.resultData[i].finished = false;
    if (this.resultData[i].values[j] == '1') {
      this.resultData[i].values[j] = '0';
    } else {
      this.resultData[i].values[j] = '1';
    }
  }

  quastionConfirm1(index) {
    this.resultData[index].finished = true;
    this.saveData();
  }

  correctAnswerCheck(i, j) {
    if (!this.resultData[i].resultIsIncorrect[j] && this.resultData[i].finished){
      if (document.activeElement && document.activeElement !== document.body) {
        this.renderer.invokeElementMethod(
          this.elRef.nativeElement.ownerDocument.activeElement, 'blur');
      }
      this.resultData[i].resultIsIncorrect[j] = true;
      this.resultData[i].correctAnswers--;
      this.saveData();
    }
  }

  saveData() {
    this.toastr.info('Saving...');
    this.httpService.post('/smartpdf/update', {
      type: this.type,
      user_id: this.student._id,
      data: {
        result: this.resultData
      }
    }).then(
      (res: APIResponse) => {
        this.toastr.success('Saved');
      }, () => {
      }
    );
  }
}
